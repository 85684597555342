<template>
  <div>
    <b-form-group id="name-group" label="Title:" label-for="title">
      <b-form-input
        id="name"
        v-model="model.title"
        placeholder="Title"
      ></b-form-input>
    </b-form-group>

    <b-form-group id="type-group" label="Comment" label-for="comment">
      <b-form-input
        id="type"
        v-model="model.comment"
        placeholder="Comment"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<style scoped>
</style>

<script>
import HelpStore from "@/store/helpstore.js";

export default {
  name: "MetatextNewEdit",
  props: ["metatext"],
  data() {
    return {
      model: this.metatext
        ? this.metatext
        : {
            id: null,
            title: '',
            comment: '',
          },
    };
  },
  mounted() {
    HelpStore.item = this.model;
  },
};
</script>
