<template>
  <div>
    <PageTitle v-bind:title="'Metatexts'" />

    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col>
          <b-button v-if="config.editable" variant="primary" :disabled="!$store.getters.checkRole('legam.bib.metatext.add')" size="sm" class="float-right mb-4" v-b-modal.modal-new-metatext>
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new Metatext
          </b-button>
          <b-modal v-if="config.editable" id="modal-new-metatext" title="New scholar" ok-title="Save" @ok="saveMetatext" size="lg">
            <MetatextNewEdit />
          </b-modal>
          <LegamDataTables
            v-bind="$data"
            @selectChanged="selectChanged"
            v-if="config.editable"
          />
          <LegamDataTablesClientSide v-else v-bind="$data" @click="openDetails" />
        </b-col>
      </b-row>
    </b-container>

    <div class="merge-float" v-if="selected.length">
      <b-badge href="#" variant="info" class="mr-1" v-for="metatext in selected" :key="metatext.id" @click="removeSelected(metatext)">
        <span v-html="metatext.title"></span>
        <b-icon icon="trash" aria-hidden="true" class="ml-1"></b-icon>
      </b-badge>
      <span class="btn-legam-xxs btn-legam-xs btn-legam-blue" v-if="selected.length > 1" v-b-modal.modal-merge>
        <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
        Merge metatexts
      </span>
      <span class="btn-legam-xxs btn-legam-xs" @click="selected = []">
        <b-icon icon="x" aria-hidden="true"></b-icon>
      </span>
      <b-modal id="modal-merge" title="Merge metatexts" ok-title="Merge" @ok="merge" size="md">
        <b-badge href="#" variant="info" class="mr-1" v-for="metatext in selected" :key="'modal' + metatext.id" @click="removeSelected(metatext)">
          <span v-html="metatext.title"></span>
          <b-icon icon="trash" aria-hidden="true" class="ml-1"></b-icon>
        </b-badge>
        <b-form-group
          id="main-entity-grp"
          label="Merge to"
          label-for="main-entity"
          class="mt-3"
          description="This metatext will keep all it's data (other metatexts will move their data to this metatext and then disappear)"
        >
          <b-form-select
            v-model="mainEntityId"
            :options="selected.map(metatext => {
              return {
                'text': metatext.title,
                'value': metatext.id
              }
            })"
          ></b-form-select>
        </b-form-group>
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  * >>> .tdAction {
    width: 150px;
  }
  * >>> .tdManuscriptDates {
    width: 130px;
  }

  * >>> .btn-legam-dt-filter:hover ~ * >>> .filter-box {
    display: block;
  }
  .merge-float {
    position: fixed;
    bottom: 15px;
    left: 20px;
    background-color: #bebebe9a;
    padding: 3px 8px 5px;
    border-radius: 3px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  import tdColAction from "@/components/tdColAction"
  import MetatextNewEdit from "@/components/Bibliography/MetatextNewEdit"
  import tdColUUID from "@/components/tdColUUID"
  import HelpStore from '@/store/helpstore.js'
  import config from '@/config.js'

  export default {
    name: 'MetatextList',
    data(){
      return {
        columns: [
          { title: 'ID', field: 'id', sortable: true, searchable: true, tdComp: tdColUUID },
          { title: 'Title', field: 'title', sortable: true, searchable: true },
          { title: 'Comment', field: 'comment', sortable: true, searchable: true },
          { title: 'Action', field: 'id', tdComp: tdColAction, thClass: 'tdAction',
            transformData: function(row){
              return [
                { text: null, icon: 'list-ul', id: row.sigleLegam, to: {
                  name: 'MetatextDetails',
                  params: {
                    id: row.id
                  }}
                }
              ]
            }
          }
        ],
        columnsPI: [
          { title: 'Title', field: 'title', sortable: true, searchable: true },
          { title: 'Comment', field: 'comment', sortable: true, searchable: true },
        ],
        url: '/metatexts/datatables',
        key: 1,
        searchableByColumn: true,
        selected: [],
        mainEntityId: null,
        config: config,
      }
    },
    computed: {
      ...mapState({
        userSettings: state => state.user.settings
      })
    },
    components: {
      PageTitle,
      LegamDataTables,
      LegamDataTablesClientSide,
      MetatextNewEdit
    },
    methods: {
      saveMetatext(){
        this.$store.dispatch('createMetatext', HelpStore.item).then((response) => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Metatext created`
          })
          router.push(`/metatext/${response.data.id}`)
        })
      },
      selectChanged(items){
        this.selected = items
        if (items.length){
          this.mainEntityId = items[0].id
        }
      },
      openDetails(data) {
        router.push(`/metatext/${data.id}`)
      },
      removeSelected(item) {
        this.selected = this.selected.filter(_item => item.id != _item.id)
      },
      merge() {
        this.$store.dispatch('mergeMetatexts', {
          mainMetatextId: this.mainEntityId,
          metatextIds: this.selected.filter(metatext => metatext.id != this.mainEntityId).map(metatext => metatext.id)
        }).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Metatexts merged`
          })
          this.selected = []
          this.mainEntityId = null
          this.key++
        })
      },
    },
    watch: {
      "userSettings"(){
        this.key++
      }
    }
  }
</script>
